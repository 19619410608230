import { useOtherDetailsByWorkspace } from "hooks/useOtherDetails";
import { createContext } from "react";

export const OtherDetailsContext = createContext({
  value: null as any,
  loading: true,
});

export function OtherDetailsProvider({ children }: { children: React.ReactNode }) {
  const details = useOtherDetailsByWorkspace();
  return (
    <OtherDetailsContext.Provider value={details}>
      {children}
    </OtherDetailsContext.Provider>
  );
}
