// Your app name
export const APP_NAME = "Flybird Chat";

// The default theme of the web app
export const DEFAULT_THEME = "theme01";

// The number of files in the `public/stickers` folder
export const STICKERS_COUNT = 78;

// The number of files in the `public/themes` folder
export const THEMES_COUNT = 26;

// The max number of characters a message can have
export const MESSAGE_MAX_CHARACTERS = 12000;

// The number of messages per "page" (pagination)
export const MESSAGES_PER_PAGE = 30;

// Use email fast sign in (DEVELOPMENT ONLY)
export const FAKE_EMAIL = true;

// export const MAIN_URL = "https://uteamwork.com";
// export const PAD_URL = "https://pad.uteamwork.com";
// export const MEETING_URL = "https://meeting.uteamwork.com";
// export const RECORD_URL = "https://record.uteamwork.com";
// export const PREVIEW_URL = "http://uteamwork.com:8012"

// export const getAPIUrl = () => {
//   return typeof window !== "undefined"
//     ? process.env.REACT_APP_API_URL ||
//         `https://im.flybird360.com:3001`
//     : "";
// };

// export const getGQLServerUrl = () => {
//   return typeof window !== "undefined"
//     ? process.env.REACT_APP_GQL_SERVER_URL ||
//         `https://im.flybird360.com:3003`
//     : "";
// };


export const MAIN_URL = "https://eoffice.tw";
export const PAD_URL = "https://pad.eoffice.tw";
export const MEETING_URL = "https://meeting.eoffice.tw";
export const RECORD_URL = "https://record.eoffice.tw";
export const PREVIEW_URL = "http://eoffice.tw.:8012"

export const getAPIUrl = () => {
  return typeof window !== "undefined"
    ? process.env.REACT_APP_API_URL ||
        `https://im.eoffice.tw`
    : "";
}; 

export const getGQLServerUrl = () => {
  return typeof window !== "undefined"
    ? process.env.REACT_APP_GQL_SERVER_URL ||
        `https://im.eoffice.tw:3003`
    : "";
};
